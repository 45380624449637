import { useQueryClient } from "@tanstack/vue-query";
import { authQueries } from "~/domains/auth/queries";

export default defineNuxtRouteMiddleware(async (to) => {
  const user = await getUser();
  if (!user) {
    return navigateTo(
      {
        path: useBaseDomainURL("/donor/login"),
        query: { return_to: useRequestURL().origin + to.fullPath },
      },
      { replace: true, redirectCode: 302, external: true }
    );
  }
});

async function getUser() {
  const queryClient = useQueryClient();
  const user = await queryClient.fetchQuery(authQueries.self());

  return user;
}
